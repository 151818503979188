import AbstractComponent from "../AbstractComponent";
import ModalDetail from "../layout/modal/ModalDetail";
import EnderecoLista from "./EnderecoLista";

class ModalEnderecoLista extends AbstractComponent {
    render() {
        return (
            <ModalDetail
                show={this.props.show}
                header={this.props.title}
                icon="down"
                fechar={() => this.props.fechar()}
            >
                
                <EnderecoLista                    
                    cidadesDisponiveis = {this.props.cidadesDisponiveis}
                    empresa={this.props.empresa}
                    escolhaEntrega={true}

                    onTitulo={(titulo) => this.onTitulo(titulo)} 
                    onUpdateEnderecoEntrega={(endereco, clienteEndereco) => this.props.onUpdateEnderecoEntrega(endereco, clienteEndereco)}                                       
                    
                    route={(item) =>
                        this.browserRoute.history.push(item.link)
                    }
                />
            </ModalDetail>
        );
    }
}

export default ModalEnderecoLista;
