import React from 'react';
import AbstractComponent from '../../AbstractComponent';
import Content from '../../layout/content/Content';
import VitrineContent from './VitrineContent';

class Vitrine extends AbstractComponent {
    fetchMoreData() {
        if(this.props.fetchMoreData === undefined)
            return ;
        
        this.props.fetchMoreData();
    }
    acessarProdsCampanha(imagemRef){
        this.props.onPressAcessarProdsCampanha(imagemRef);
    }

    render() {
        return (
        <>

            <Content
                preload={this.props.inPreload}
                className={this.props.className}

                showSummary={false}
                breadcrumb={this.props.breadcrumb}
                showHeader={this.props.breadcrumb !== undefined}

                component={(props) => {
                    return <VitrineContent
                                {...props}
                                onPressAcessarProdsCampanha={(imagemRef) => {this.acessarProdsCampanha(imagemRef)}}
                                inPreload={this.props.inPreload}
                                vitrinePreload={this.props.vitrinePreload}
                                telaFavorito={this.props.telaFavorito}
                                campanha={this.props.campanha}
                                campanhaProdutos={this.props.campanhaProdutos}
                                vitrine={this.props.vitrine}
                                carrinho={this.props.carrinho}                                
                                searchKey={this.props.searchKey}                                

                                onUpdateCart={(carrinho, indiceItem, indiceCarrinhoItem, quantidade) => this.props.onUpdateCart(carrinho, indiceItem, indiceCarrinhoItem, quantidade)}
                                changeQuantidade={(item, quantidade) => this.props.changeQuantidade(item, quantidade)}
                                onUpdateVitrine={(index) => this.props.onUpdateVitrine(index)}
                                onUpdateBadgeFavorito={(tipo, index) => this.props.onUpdateBadgeFavorito(tipo, index)}
                                fetchMoreData={() => this.fetchMoreData()}
                                filtros = {this.props.filtros}
                                route={(item) => this.props.route(item)}
                            />
                }}
            />
        </>)
    }
}

Vitrine.defaultProps = {
    className: ""
}

export default Vitrine;