import React, { Fragment } from 'react';
import AbstractComponent from '../AbstractComponent';
import OpcaoEntrega from '../shopping/fechamento/opcao-entrega/OpcaoEntrega';
import OpcaoPagamento from '../shopping/fechamento/opcao-pagamento/OpcaoPagamento';
import OpcaoFrete from './OpcaoFrete';

import AutenticarService from '../../service/AutenticarService';
import CorreiosService from "../../service/CorreiosService";

import TipoCalculoFreteEnum from '../../helper/enumerador/TipoCalculoFreteEnum';

import Config from "../../Config"

import "./pagamento.css"


class Fechamento extends AbstractComponent {
    constructor(props) {
        super(props);        

        this.state = {           

            chooseOpcaoEntrega: false,
            
            chooseOpcaoFrete: false,

            fretes: []
        }
    }    
        
    componentDidMount() {        
        if (this.props.tipoCalculoFrete === TipoCalculoFreteEnum.TRANSPORTADORA.name) {
            this.props.inPreload(true)
            CorreiosService.cotacao(
                AutenticarService.getDocumentoCliente(),           
                AutenticarService.getIdCarrinho(),
                this.props.cliente.cep
            ).then((response) => {
                this.setState({
                fretes: response.data,
                });
                this.props.inPreload(false)
            }).catch((error) => {
                this.props.inPreload(false)                
            })
        } else {
            this.props.inPreload(false)
        }
    }

    height() {
        return this.props.opcaoEntrega.length > 1
            ? '234px'
            : '285px'
    }

    render() {
        return (
            <div style={{ height: this.height(), padding: '5px' }}>  
                <h4>
                    <img src={Config.urlImgBase + "frete.svg"} alt="Frete" style={{width: '25'}}/>
                    &nbsp;
                    &nbsp;
                    <strong>Selecione o tipo de entrega</strong>
                </h4>

                {this.props.opcaoEntrega.length > 1 &&                 
                    <OpcaoEntrega
                        cidadesDisponiveis = {this.props.cidadesDisponiveis}                                 
                        cliente={this.props.cliente}  
                        empresa={this.props.empresa}                       
                        carrinho={this.props.carrinho}                                      
                        opcaoEntrega={this.props.opcaoEntrega}                    
                        
                        chooseOpcaoEntrega={this.state.chooseOpcaoEntrega}

                        onUpdateCart={(cart) => this.props.onUpdateCart(cart)}
                        onUpdateEnderecoEntrega={(endereco, clienteEndereco) => this.props.onUpdateEnderecoEntrega(endereco, clienteEndereco)}                    
                        fecharChooseOpcaoEntrega={() => this.setState({chooseOpcaoEntrega: false})}

                        route={(item) => this.props.route(item)}
                    />
                }
                
                {
                  this.props.tipoCalculoFrete === TipoCalculoFreteEnum.TRANSPORTADORA.name && this.props.carrinho.entrega.id === 2 &&
                    <OpcaoFrete
                        carrinho={this.props.carrinho}
                        fretes={this.state.fretes}
                        chooseOpcaoFrete={this.state.chooseOpcaoFrete}
                            
                        onUpdateCart={(cart) => this.props.onUpdateCart(cart)}

                        route={(item) => this.props.route(item)}
                    />                    
                }

                {
                  (this.props.tipoCalculoFrete !== TipoCalculoFreteEnum.TRANSPORTADORA.name || this.props.carrinho.entrega.id === 1 || this.props.carrinho.entrega.frete.servico !== "") &&
                    <OpcaoPagamento                                                            
                        carrinho={this.props.carrinho}
                        cliente={this.props.cliente}  
                        empresa={this.props.empresa}                  
                        modalidadePagamento={this.props.modalidadePagamento}
                        pagamento={this.props.pagamento}     
                        opcaoEntrega={this.props.opcaoEntrega}
                        usaCheckout={true}                        
                        
                        chooseOpcaoPagamento={this.state.chooseOpcaoPagamento}
                        chooseOpcaoEntrega={this.state.chooseOpcaoEntrega}
                                            
                        onUpdatePagamento={(id, descricao, troco, opcaoPagamento, permite_parcelamento) => this.props.onUpdatePagamento(id, descricao, troco, opcaoPagamento, permite_parcelamento)}
                        onUpdateCart={(cart) => this.onUpdateCart(cart)}
                        onUpdateEnderecoEntrega={(endereco, clienteEndereco) => this.onUpdateEnderecoEntrega(endereco, clienteEndereco)}
                        fecharChooseOpcaoPagamento={() => this.setState({chooseOpcaoPagamento: false})}

                        route={(item) => this.props.route(item)}
                    />
                }
            </div>
        )
    }
}

export default Fechamento;
